<template>
<div class="modal fade " id="updateAvatarModal" tabindex="-1" role="dialog" aria-labelledby="updateAvatarModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Avatar </h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">

                        <div class="col-12">
                            <div class="text-center py-2">
                                <img :src="avatar" class="img-fluid" alt="" srcset="">
                            </div>
                            <h6 class="text-dark ">Upload Image</h6>
                            <div class="form-group">
                                <div class="controls">
                                    <input type="file" @change="showPreview" class="form-control">
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="uploadImage">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Update </span>
                </button>

            </div>
            <div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
    name: "ImageUpdateModal",
    props: ['previousImage'],
    mixins: [ShowToastMessage, Loader, ],

    data() {
        return {
            avatar: "",
            imageFile: '',
          
        }
    },
    watch: {
      previousImage(currentImage) {
            if (currentImage) {
                this.avatar = currentImage
            }
        }
    },
    methods: {
      
        showPreview(event) {
            if (event.target.files.length > 0 && event.target.files[0].type.match('image.*')) {
                this.avatar = URL.createObjectURL(event.target.files[0]);
                this.imageFile = event.target.files[0];
                // this.createBase64Image(event.target.files[0]);

            } else {

                this.showToastMessage({
                    type: "error",
                    message: 'Please upload image file(png,jpg,jpeg) only'
                });
            }
        },

        async uploadImage() {
            if (this.imageFile) {
                this.loader(true);
                const reader = new FileReader();

                reader.onload = async (e) => {
                 
                    this.$emit('getSingleView', e.target.result);

                   
                };
                reader.readAsDataURL(this.imageFile);
            } else {
                this.showToastMessage({
                    type: "error",
                    message: 'Please upload image file(png,jpg,jpeg)'
                });
            }

        }

    },

}
</script>

<style>

</style>
